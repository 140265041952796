import email from 'lib/validators/email'
import required from 'lib/validators/required'

export default {
  login: [
    required,
    email,
  ],
  password: [
    required,
  ],
}
