<template>
  <AuthLayout
    class="auth-partner"
    :title="locale.title"
  >
    <AuthPartnerSigninForm />

    <template #after-content>
      <div class="text-s-regular">
        <router-link :to="{name: 'auth-reset-password'}">
          {{ locale.forgotPassword }}
        </router-link>
      </div>
    </template>

    <template #footer>
      <div class="text-m-regular text-center">
        {{ locale.wannaLaunchAd }}
        <br>
        <router-link :to="{name: 'auth-partner-request'}">
          <TextLink>
            {{ locale.contactUs }}
          </TextLink>
        </router-link>
      </div>

      <div class="text-m-regular text-center auth-partner__signin">
        <router-link :to="{name: 'auth-streamer'}">
          <TextLink>
            {{ locale.streamerLogin }}
          </TextLink>
        </router-link>
      </div>
    </template>
  </AuthLayout>
</template>

<script setup>
import TextLink from 'components/buttons/TextLink'
import AuthLayout from 'components/layouts/AuthLayout'
import { useLocale } from 'lib/helpers/useLocale'
import { computed } from 'vue'

import AuthPartnerSigninForm from '@/views/Auth/components/AuthPartnerSigninForm'

const locale = computed(() => ({
  title: useLocale('title'),
  forgotPassword: useLocale('forgotPassword'),
  streamerLogin: useLocale('streamerLogin'),
  wannaLaunchAd: useLocale('wannaLaunchAd'),
  contactUs: useLocale('contactUs'),
}))
</script>

<style lang="scss" scoped>
.auth-partner {
  &__signin {
    border-top: 1px solid $--border-color-base;
    padding-top: 16px;
    margin-top: 16px;
  }
}
</style>
